
<template>
  <div>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-select v-model="reqType"   @change="selected" :items="reqTypes" item-text="label" item-value="id" label="Choose Dashboard Report Request"></v-select>
          </v-col>
        </v-row>


        <v-row v-if="reqType== 0 || reqType==1 || reqType==2  || reqType==3">
          <v-col cols="12" sm="6" md="6">
            <DatePicker v-model="dateFrom" label="From"></DatePicker>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <DatePicker v-model="dateTo" label="To"></DatePicker>
          </v-col>
            <v-col cols="12" sm="6" md="6">
            <v-select v-model="request_type" :items="request_types" item-text="description_en" item-value="id" label="Request Type"></v-select>
          </v-col>
          
           <v-col cols="12" sm="6" md="6">
            <v-select v-model="request_status" :items="getLookup('ReqStatus')" item-value="id" label="Request Status"></v-select>
          </v-col>
          
        </v-row>

  

        <v-btn v-if="reqType!= -1" color="primary" dark class="mb-2" @click="generateReport">{{$t('View Report')}}</v-btn>
        <v-btn v-if="reqType!= -1" color="primary" dark class="mb-2" @click="closeReport">{{$t('Close Report')}}</v-btn>


      </v-card-text>
    </v-card>
    <dashboardCondensed v-if="reqType === 0 && flag==1" :reportId="reqType" :dateFrom="dateFrom" :dateTo="dateTo" :organisation="this.$store.getters.userInfo"></dashboardCondensed>
    <dashboardFull v-if="reqType === 1 && flag==1" :reportId="reqType" :dateFrom="dateFrom" :dateTo="dateTo" :organisation="this.$store.getters.userInfo"></dashboardFull>
    <dashboardSenior v-if="reqType === 2 && flag==1" :reportId="reqType" :dateFrom="dateFrom" :dateTo="dateTo" :organisation="this.$store.getters.userInfo"></dashboardSenior>
    <dashboardStandard v-if="reqType === 3 && flag==1" :reportId="reqType" :dateFrom="dateFrom" :dateTo="dateTo" :requesttype="this.request_type" :requeststatus="request_status" :reporttype="report_type"></dashboardStandard>
 

    <div v-else>
      <v-progress-circular
          v-if="flag==-2"
          :size="50"
          color="primary"
          indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>

import DatePicker from "../components/DatePicker";
import dashboardCondensed from "../components/report/dashboardCondensed";
import dashboardFull from "../components/report/dashboardFull";
import dashboardSenior from "../components/report/dashboardSenior";
import dashboardStandard from "../components/report/dashboardStandard";

import { mapState,mapActions } from "vuex";
export default {
  created () {
     this.$store.dispatch("configuration/retriveConfigurations");
    this.$store.dispatch("configuration/retriveConfigChoices");
  },
  components: {
    DatePicker,
    dashboardCondensed,
    dashboardFull,
    dashboardSenior,
    dashboardStandard,
  
  },
  data(){
    return {
      flag:0,
      reqType:"-1",
      request_status:"",
      reqTypes: [
        {
          id:0,
          label: "Request Dashboard-Condensed",
        },
        {
          id:1,
          label: "Request Dashboard-Full",
        },
        {
          id:2,
          label: "Request Dashboard-Senior Mgt",
        },
        {
          id:3,
          label: "Completed Access to Information Requests",
        }

      ],
       request_type:0,
       
      dateFrom: new Date().toISOString().substr(0, 10),
      dateTo: new Date().toISOString().substr(0, 10),
      Organisations:[
        { id:'IC',name:"Industry Canada"}

      ],
      
      Organisation:"Industry Canada",
        report_types:[
        { id:'0',name:"Active Complaints"},
        { id:'1',name:"Completed Complaints"},
        { id:'2',name:"Recieved Complaints"}


      ],
      report_type:"Active Complaints",

    };
  },
  methods:{

          getLookup(column){
      return this.configurations.filter( filter => filter.LookUpName === column ).map( item => {
        let arr = [];
        arr['text'] = this.$vuetify.lang.current === 'en' ? item.LookUpStringE : item.LookUpStringF;
        arr['value'] = item.LookUpKey;
        return  arr;
      })
    },

    selected(){

    },
    generateReport(){
      this.flag = 1;
    },
    closeReport(){
      this.flag = 0;
    },

   ...mapActions(["retriveTypes"]),
  },

     computed: {

   ...mapState('configuration', {
      configurations: 'configurations',
      loading:'loading',
      requestConfiguration:'requestConfiguration'
    }),

      ...mapState({
      request_types: "types",
    }),
   },

      mounted () {
         this.retriveTypes();
    },
  

};



</script>


<style lang="scss" scoped>

</style>

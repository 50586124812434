<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col  cols="12" sm="12" md="12">


<table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
 style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
 <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
  <td style='padding:0in 0in 0in 0in'>
  <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
   style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
   <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
    <td style='padding:0in 0in 0in 0in'>
    <div>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
     width=1312 style='width:984.1pt;border-collapse:collapse;mso-yfti-tbllook:
     1184;mso-padding-alt:0in 0in 0in 0in'>
     <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;height:.25in'>
      <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in;
      height:.25in'></td>
      <td width=3 valign=top style='width:2.25pt;padding:0in 0in 0in 0in;
      height:.25in;min-width: 0.79mm'></td>
      <td width=1309 valign=top style='width:981.85pt;padding:0in 0in 0in 0in;
      height:.25in;min-width: 346.34mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:.25in'>
        <td width=1105 valign=top style='width:828.85pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in;min-width: 292.36mm'>
        <p class=MsoNormal align=center style='text-align:center'><b><span
        style='font-size:14.0pt;mso-fareast-font-family:"Times New Roman";
        color:black'>Request Dashboard - Condensed<o:p></o:p></span></b></p>
        </td>
       </tr>
      </table>
      </td>
     </tr>
     <tr style='mso-yfti-irow:1;mso-yfti-lastrow:yes;height:22.0pt'>
      <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in;
      height:22.0pt'></td>
      <td width=3 valign=top style='width:2.25pt;padding:0in 0in 0in 0in;
      height:22.0pt;min-width: 0.79mm'></td>
      <td width=1309 valign=top style='width:981.85pt;padding:0in 0in 0in 0in;
      height:22.0pt;min-width: 346.34mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:.25in'>
        <td width=1105 valign=top style='width:828.85pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in;min-width: 292.36mm'>
        <p class=MsoNormal align=center style='text-align:center'><b><span
        style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
        color:black'>Request Type: Access Requests (A), Request Status: Active
        Requests, Report Type: Requests with Date Received from 2011-10-07 to
        2020-11-13<o:p></o:p></span></b></p>
        </td>
       </tr>
      </table>
      </td>
     </tr>
    </table>
    </div>
    </td>
   </tr>
   <tr style='mso-yfti-irow:1'>
    <td style='padding:0in 0in 0in 0in'>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
     style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'
     cols=3>
     <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes'>
      <td valign=top style='padding:0in 0in 0in 0in'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       width=1312 style='width:984.1pt;border-collapse:collapse;mso-yfti-tbllook:
       1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;height:2.5pt'>
        <td width=0 style='width:.3pt;padding:0in 0in 0in 0in;height:2.5pt'></td>
        <td width=3 style='width:2.25pt;padding:0in 0in 0in 0in;height:2.5pt;
        min-width: 0.79mm'></td>
        <td width=1309 style='width:981.85pt;padding:0in 0in 0in 0in;
        height:2.5pt;min-width: 346.34mm'></td>
       </tr>
       <tr style='mso-yfti-irow:1;mso-yfti-lastrow:yes'>
        <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in'></td>
        <td width=3 valign=top style='width:2.25pt;padding:0in 0in 0in 0in;
        min-width: 0.79mm'></td>
        <td width=1309 valign=top style='width:981.85pt;padding:0in 0in 0in 0in;
        min-width: 346.34mm'>
        <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
         width=1309 style='width:981.85pt;border-collapse:collapse;mso-yfti-tbllook:
         1184;mso-padding-alt:0in 0in 0in 0in;min-width: 346.34mm' cols=18>
         <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
          <td></td>
          <td width=86 style='width:64.5pt;padding:0in 0in 0in 0in;min-width: 22.75mm'></td>
          <td width=51 style='width:38.25pt;padding:0in 0in 0in 0in;min-width: 13.49mm'></td>
          <td width=177 style='width:132.75pt;padding:0in 0in 0in 0in;
          min-width: 46.83mm'></td>
          <td width=76 style='width:57.0pt;padding:0in 0in 0in 0in;min-width: 20.11mm'></td>
          <td width=76 style='width:57.0pt;padding:0in 0in 0in 0in;min-width: 20.11mm'></td>
          <td width=96 style='width:1.0in;padding:0in 0in 0in 0in;min-width: 25.40mm'></td>
          <td width=96 style='width:1.0in;padding:0in 0in 0in 0in;min-width: 25.40mm'></td>
          <td width=127 style='width:95.25pt;padding:0in 0in 0in 0in;
          min-width: 33.60mm'></td>
          <td width=85 style='width:63.75pt;padding:0in 0in 0in 0in;min-width: 22.49mm'></td>
          <td width=81 style='width:60.75pt;padding:0in 0in 0in 0in;min-width: 21.43mm'></td>
          <td width=51 style='width:38.25pt;padding:0in 0in 0in 0in;min-width: 13.49mm'></td>
          <td width=45 style='width:33.75pt;padding:0in 0in 0in 0in;min-width: 11.91mm'></td>
          <td width=40 style='width:30.0pt;padding:0in 0in 0in 0in;min-width: 10.58mm'></td>
          <td width=50 style='width:37.5pt;padding:0in 0in 0in 0in;min-width: 13.23mm'></td>
          <td width=43 style='width:32.25pt;padding:0in 0in 0in 0in;min-width: 11.38mm'></td>
          <td width=49 style='width:36.75pt;padding:0in 0in 0in 0in;min-width: 12.96mm'></td>
          <td width=45 style='width:33.75pt;padding:0in 0in 0in 0in;min-width: 11.91mm'></td>
         </tr>
         <tr style='mso-yfti-irow:1;height:.25in'>
          <td width=1309 colspan=18 valign=top style='width:981.85pt;
          border:solid darkgray 1.0pt;border-bottom:none;mso-border-top-alt:
          solid darkgray .25pt;mso-border-left-alt:solid darkgray .25pt;
          mso-border-right-alt:solid darkgray .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='mso-fareast-font-family:"Times New Roman";color:black'>Request
          Details<o:p></o:p></span></i></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:2;height:.25in'>
          <td width=35 valign=top style='width:26.25pt;border:solid darkgray 1.0pt;
          border-bottom:none;mso-border-top-alt:solid darkgray .25pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-right-alt:solid darkgray .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=86 valign=top style='width:64.5pt;border-top:solid darkgray 1.0pt;
          border-left:none;border-bottom:none;border-right:solid darkgray 1.0pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-top-alt:solid darkgray .25pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-right-alt:solid darkgray .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:solid darkgray 1.0pt;
          border-left:none;border-bottom:none;border-right:solid darkgray 1.0pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-top-alt:solid darkgray .25pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-right-alt:solid darkgray .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=177 valign=top style='width:132.75pt;border-top:solid darkgray 1.0pt;
          border-left:none;border-bottom:none;border-right:solid darkgray 1.0pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-top-alt:solid darkgray .25pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-right-alt:solid darkgray .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:solid darkgray 1.0pt;
          border-left:none;border-bottom:none;border-right:solid darkgray 1.0pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-top-alt:solid darkgray .25pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-right-alt:solid darkgray .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:solid darkgray 1.0pt;
          border-left:none;border-bottom:none;border-right:solid darkgray 1.0pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-top-alt:solid darkgray .25pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-right-alt:solid darkgray .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:solid darkgray 1.0pt;
          border-left:none;border-bottom:none;border-right:solid darkgray 1.0pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-top-alt:solid darkgray .25pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-right-alt:solid darkgray .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:solid darkgray 1.0pt;
          border-left:none;border-bottom:none;border-right:solid darkgray 1.0pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-top-alt:solid darkgray .25pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-right-alt:solid darkgray .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=127 valign=top style='width:95.25pt;border-top:solid darkgray 1.0pt;
          border-left:none;border-bottom:none;border-right:solid darkgray 1.0pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-top-alt:solid darkgray .25pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-right-alt:solid darkgray .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=85 valign=top style='width:63.75pt;border:none;border-top:
          solid silver 1.0pt;mso-border-top-alt:solid silver .25pt;padding:
          2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=81 valign=top style='width:60.75pt;border-top:solid silver 1.0pt;
          border-left:solid silver 1.0pt;border-bottom:none;border-right:none;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=136 colspan=3 valign=top style='width:102.0pt;border:solid darkgray 1.0pt;
          mso-border-alt:solid darkgray .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Extension<o:p></o:p></span></i></p>
          </td>
          <td width=93 colspan=2 valign=top style='width:69.75pt;border:solid darkgray 1.0pt;
          border-left:none;mso-border-left-alt:solid darkgray .25pt;mso-border-alt:
          solid darkgray .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Days<o:p></o:p></span></i></p>
          </td>
          <td width=94 colspan=2 valign=top style='width:70.5pt;border:solid darkgray 1.0pt;
          border-left:none;mso-border-left-alt:solid darkgray .25pt;mso-border-alt:
          solid darkgray .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Pages<o:p></o:p></span></i></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:3;height:15.1pt'>
          <td width=35 valign=top style='width:26.25pt;border-top:none;
          border-left:solid darkgray 1.0pt;border-bottom:solid silver 1.0pt;
          border-right:solid darkgray 1.0pt;mso-border-left-alt:solid darkgray .25pt;
          mso-border-bottom-alt:solid silver .25pt;mso-border-right-alt:solid darkgray .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Id<o:p></o:p></span></i></p>
          </td>
          <td width=86 valign=top style='width:64.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid darkgray 1.0pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-left-alt:solid darkgray .25pt;
          mso-border-bottom-alt:solid silver .25pt;mso-border-right-alt:solid darkgray .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Request<o:p></o:p></span></i></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid darkgray 1.0pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-left-alt:solid darkgray .25pt;
          mso-border-bottom-alt:solid silver .25pt;mso-border-right-alt:solid darkgray .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Officer<o:p></o:p></span></i></p>
          </td>
          <td width=177 valign=top style='width:132.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid darkgray 1.0pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-left-alt:solid darkgray .25pt;
          mso-border-bottom-alt:solid silver .25pt;mso-border-right-alt:solid darkgray .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Summary Text<o:p></o:p></span></i></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid darkgray 1.0pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-left-alt:solid darkgray .25pt;
          mso-border-bottom-alt:solid silver .25pt;mso-border-right-alt:solid darkgray .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Applicant<o:p></o:p></span></i></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid darkgray 1.0pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-left-alt:solid darkgray .25pt;
          mso-border-bottom-alt:solid silver .25pt;mso-border-right-alt:solid darkgray .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Source<o:p></o:p></span></i></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid darkgray 1.0pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-left-alt:solid darkgray .25pt;
          mso-border-bottom-alt:solid silver .25pt;mso-border-right-alt:solid darkgray .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Correspondence<o:p></o:p></span></i></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid darkgray 1.0pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-left-alt:solid darkgray .25pt;
          mso-border-bottom-alt:solid silver .25pt;mso-border-right-alt:solid darkgray .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Copy Of<o:p></o:p></span></i></p>
          </td>
          <td width=127 valign=top style='width:95.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid darkgray 1.0pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-left-alt:solid darkgray .25pt;
          mso-border-bottom-alt:solid silver .25pt;mso-border-right-alt:solid darkgray .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Date Complete Received<o:p></o:p></span></i></p>
          </td>
          <td width=85 valign=top style='width:63.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Due Date<o:p></o:p></span></i></p>
          </td>
          <td width=81 valign=top style='width:60.75pt;border:none;border-left:
          solid silver 1.0pt;mso-border-left-alt:solid silver .25pt;padding:
          2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Date Closed<o:p></o:p></span></i></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:solid darkgray 1.0pt;border-bottom:solid silver 1.0pt;
          border-right:solid darkgray 1.0pt;mso-border-top-alt:solid darkgray .25pt;
          mso-border-alt:solid darkgray .25pt;mso-border-bottom-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Search<o:p></o:p></span></i></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:solid darkgray 1.0pt;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid darkgray 1.0pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-alt:solid darkgray .25pt;
          mso-border-bottom-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>3rd Pty<o:p></o:p></span></i></p>
          </td>
          <td width=40 valign=top style='width:30.0pt;border-top:solid darkgray 1.0pt;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid darkgray 1.0pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-alt:solid darkgray .25pt;
          mso-border-bottom-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Conslt<o:p></o:p></span></i></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid darkgray 1.0pt;
          mso-border-top-alt:solid darkgray .25pt;mso-border-left-alt:solid darkgray .25pt;
          mso-border-alt:solid darkgray .25pt;mso-border-bottom-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Allowed<o:p></o:p></span></i></p>
          </td>
          <td width=43 valign=top style='width:32.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid darkgray 1.0pt;
          mso-border-top-alt:solid darkgray .25pt;mso-border-left-alt:solid darkgray .25pt;
          mso-border-alt:solid darkgray .25pt;mso-border-bottom-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Taken<o:p></o:p></span></i></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid darkgray 1.0pt;
          mso-border-top-alt:solid darkgray .25pt;mso-border-left-alt:solid darkgray .25pt;
          mso-border-alt:solid darkgray .25pt;mso-border-bottom-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Review<o:p></o:p></span></i></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid darkgray 1.0pt;
          mso-border-top-alt:solid darkgray .25pt;mso-border-left-alt:solid darkgray .25pt;
          mso-border-alt:solid darkgray .25pt;mso-border-bottom-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Release<o:p></o:p></span></i></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:4;height:15.1pt'>
          <td width=35 valign=top style='width:26.25pt;border:solid silver 1.0pt;
          border-top:none;mso-border-top-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>1<o:p></o:p></span></p>
          </td>
          <td width=86 valign=top style='width:64.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><u><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:blue'><a
          href="http://hpvs426/Axcess1/axcess1/Request_Detail.aspx?ReqIdentity=20198">A-2017-00006</a><o:p></o:p></span></u></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>ML<o:p></o:p></span></p>
          </td>
          <td width=177 valign=top style='width:132.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>All briefing materials and memos prepared for the CEO of
          Sustainable Development Technology Canada in February 2018.<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Alia Rotherman<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Media<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Mail<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=127 valign=top style='width:95.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-04-13<o:p></o:p></span></p>
          </td>
          <td width=85 valign=top style='width:63.75pt;border:solid silver 1.0pt;
          border-left:none;mso-border-left-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-06-12<o:p></o:p></span></p>
          </td>
          <td width=81 valign=top style='width:60.75pt;border:solid silver 1.0pt;
          border-left:none;mso-border-left-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>30<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=40 valign=top style='width:30.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>60<o:p></o:p></span></p>
          </td>
          <td width=43 valign=top style='width:32.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>945<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>480<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>102<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:5;height:15.1pt'>
          <td width=35 valign=top style='width:26.25pt;border:solid silver 1.0pt;
          border-top:none;mso-border-top-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2<o:p></o:p></span></p>
          </td>
          <td width=86 valign=top style='width:64.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><u><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:blue'><a
          href="http://hpvs426/Axcess1/axcess1/Request_Detail.aspx?ReqIdentity=20199">A-2018-0001</a><o:p></o:p></span></u></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>ML<o:p></o:p></span></p>
          </td>
          <td width=177 valign=top style='width:132.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>All agreements, applications, contractual documents, or
          records relating to any agreement, application or contract between
          SDTC and Enerkem.<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Leah McDaniel<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Business<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Online Request<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=127 valign=top style='width:95.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-04-10<o:p></o:p></span></p>
          </td>
          <td width=85 valign=top style='width:63.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-07-09<o:p></o:p></span></p>
          </td>
          <td width=81 valign=top style='width:60.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>60<o:p></o:p></span></p>
          </td>
          <td width=40 valign=top style='width:30.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>90<o:p></o:p></span></p>
          </td>
          <td width=43 valign=top style='width:32.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>948<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>1335<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:6;height:15.1pt'>
          <td width=35 valign=top style='width:26.25pt;border:solid silver 1.0pt;
          border-top:none;mso-border-top-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>3<o:p></o:p></span></p>
          </td>
          <td width=86 valign=top style='width:64.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><u><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:blue'><a
          href="http://hpvs426/Axcess1/axcess1/Request_Detail.aspx?ReqIdentity=20202">A-2018-0003</a><o:p></o:p></span></u></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>ML<o:p></o:p></span></p>
          </td>
          <td width=177 valign=top style='width:132.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>All briefing materials and memos prepared for the
          President &amp; CEO of SDTC for the month of March 2018<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Zebeda
          Sallinger<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Media<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Online Request<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=127 valign=top style='width:95.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=85 valign=top style='width:63.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=81 valign=top style='width:60.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=40 valign=top style='width:30.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>30<o:p></o:p></span></p>
          </td>
          <td width=43 valign=top style='width:32.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:7;height:15.1pt'>
          <td width=35 valign=top style='width:26.25pt;border:solid silver 1.0pt;
          border-top:none;mso-border-top-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>4<o:p></o:p></span></p>
          </td>
          <td width=86 valign=top style='width:64.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><u><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:blue'><a
          href="http://hpvs426/Axcess1/axcess1/Request_Detail.aspx?ReqIdentity=20203">A-2018-0004</a><o:p></o:p></span></u></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>ML<o:p></o:p></span></p>
          </td>
          <td width=177 valign=top style='width:132.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>All briefing materials and memos prepared for the
          President &amp; CEO of SDTC for the month of April 2018<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Zebeda
          Sallinger<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Media<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Online Request<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=127 valign=top style='width:95.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=85 valign=top style='width:63.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=81 valign=top style='width:60.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=40 valign=top style='width:30.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>30<o:p></o:p></span></p>
          </td>
          <td width=43 valign=top style='width:32.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:8;height:15.1pt'>
          <td width=35 valign=top style='width:26.25pt;border:solid silver 1.0pt;
          border-top:none;mso-border-top-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>5<o:p></o:p></span></p>
          </td>
          <td width=86 valign=top style='width:64.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><u><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:blue'><a
          href="http://hpvs426/Axcess1/axcess1/Request_Detail.aspx?ReqIdentity=20204">A-2018-0005</a><o:p></o:p></span></u></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>ML<o:p></o:p></span></p>
          </td>
          <td width=177 valign=top style='width:132.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Alia Rotherman<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Media<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Online Request<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=127 valign=top style='width:95.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-05-26<o:p></o:p></span></p>
          </td>
          <td width=85 valign=top style='width:63.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-06-25<o:p></o:p></span></p>
          </td>
          <td width=81 valign=top style='width:60.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=40 valign=top style='width:30.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>30<o:p></o:p></span></p>
          </td>
          <td width=43 valign=top style='width:32.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>902<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>3<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>1<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:9;height:15.1pt'>
          <td width=35 valign=top style='width:26.25pt;border:solid silver 1.0pt;
          border-top:none;mso-border-top-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>6<o:p></o:p></span></p>
          </td>
          <td width=86 valign=top style='width:64.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><u><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:blue'><a
          href="http://hpvs426/Axcess1/axcess1/Request_Detail.aspx?ReqIdentity=20205">A-2018-0006</a><o:p></o:p></span></u></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>ML<o:p></o:p></span></p>
          </td>
          <td width=177 valign=top style='width:132.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Kelly
          salamander<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Public<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Online Request<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=127 valign=top style='width:95.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-05-26<o:p></o:p></span></p>
          </td>
          <td width=85 valign=top style='width:63.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-06-25<o:p></o:p></span></p>
          </td>
          <td width=81 valign=top style='width:60.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=40 valign=top style='width:30.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>30<o:p></o:p></span></p>
          </td>
          <td width=43 valign=top style='width:32.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>902<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:10;height:15.1pt'>
          <td width=35 valign=top style='width:26.25pt;border:solid silver 1.0pt;
          border-top:none;mso-border-top-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>7<o:p></o:p></span></p>
          </td>
          <td width=86 valign=top style='width:64.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><u><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:blue'><a
          href="http://hpvs426/Axcess1/axcess1/Request_Detail.aspx?ReqIdentity=20206">A-2018-0007</a><o:p></o:p></span></u></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>ML<o:p></o:p></span></p>
          </td>
          <td width=177 valign=top style='width:132.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Kelly
          salamander<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Public<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Online Request<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=127 valign=top style='width:95.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-05-28<o:p></o:p></span></p>
          </td>
          <td width=85 valign=top style='width:63.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-06-27<o:p></o:p></span></p>
          </td>
          <td width=81 valign=top style='width:60.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=40 valign=top style='width:30.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>30<o:p></o:p></span></p>
          </td>
          <td width=43 valign=top style='width:32.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>900<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>87<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>66<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:11;height:15.1pt'>
          <td width=35 valign=top style='width:26.25pt;border:solid silver 1.0pt;
          border-top:none;mso-border-top-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>8<o:p></o:p></span></p>
          </td>
          <td width=86 valign=top style='width:64.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><u><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:blue'><a
          href="http://hpvs426/Axcess1/axcess1/Request_Detail.aspx?ReqIdentity=21206">A-2018-0008</a><o:p></o:p></span></u></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>ML<o:p></o:p></span></p>
          </td>
          <td width=177 valign=top style='width:132.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Zebeda
          Sallinger<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Media<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Online Request<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=127 valign=top style='width:95.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-06-04<o:p></o:p></span></p>
          </td>
          <td width=85 valign=top style='width:63.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-07-04<o:p></o:p></span></p>
          </td>
          <td width=81 valign=top style='width:60.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=40 valign=top style='width:30.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>30<o:p></o:p></span></p>
          </td>
          <td width=43 valign=top style='width:32.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>893<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>7<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:12;height:15.1pt'>
          <td width=35 valign=top style='width:26.25pt;border:solid silver 1.0pt;
          border-top:none;mso-border-top-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>9<o:p></o:p></span></p>
          </td>
          <td width=86 valign=top style='width:64.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><u><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:blue'><a
          href="http://hpvs426/Axcess1/axcess1/Request_Detail.aspx?ReqIdentity=21207">A-2018-0009</a><o:p></o:p></span></u></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>DG<o:p></o:p></span></p>
          </td>
          <td width=177 valign=top style='width:132.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>This is summary text<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Alia Rotherman<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Media<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Online Request<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=127 valign=top style='width:95.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-08-09<o:p></o:p></span></p>
          </td>
          <td width=85 valign=top style='width:63.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-11-22<o:p></o:p></span></p>
          </td>
          <td width=81 valign=top style='width:60.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>45<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=40 valign=top style='width:30.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>30<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>105<o:p></o:p></span></p>
          </td>
          <td width=43 valign=top style='width:32.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>827<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>3<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:13;height:15.1pt'>
          <td width=35 valign=top style='width:26.25pt;border:solid silver 1.0pt;
          border-top:none;mso-border-top-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>10<o:p></o:p></span></p>
          </td>
          <td width=86 valign=top style='width:64.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><u><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:blue'><a
          href="http://hpvs426/Axcess1/axcess1/Request_Detail.aspx?ReqIdentity=22207">A-2018-0010</a><o:p></o:p></span></u></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>DG<o:p></o:p></span></p>
          </td>
          <td width=177 valign=top style='width:132.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Angela
          Pigllywiggly<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Business<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Online Request<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=127 valign=top style='width:95.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=85 valign=top style='width:63.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=81 valign=top style='width:60.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=40 valign=top style='width:30.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>30<o:p></o:p></span></p>
          </td>
          <td width=43 valign=top style='width:32.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>38<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:14;height:15.1pt'>
          <td width=35 valign=top style='width:26.25pt;border:solid silver 1.0pt;
          border-top:none;mso-border-top-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>11<o:p></o:p></span></p>
          </td>
          <td width=86 valign=top style='width:64.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><u><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:blue'><a
          href="http://hpvs426/Axcess1/axcess1/Request_Detail.aspx?ReqIdentity=22208">A-2018-0011</a><o:p></o:p></span></u></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=177 valign=top style='width:132.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Randall
          O'Callaghan<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Business<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=127 valign=top style='width:95.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=85 valign=top style='width:63.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=81 valign=top style='width:60.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=40 valign=top style='width:30.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>30<o:p></o:p></span></p>
          </td>
          <td width=43 valign=top style='width:32.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>4<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:15;height:15.1pt'>
          <td width=35 valign=top style='width:26.25pt;border:solid silver 1.0pt;
          border-top:none;mso-border-top-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>12<o:p></o:p></span></p>
          </td>
          <td width=86 valign=top style='width:64.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><u><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:blue'><a
          href="http://hpvs426/Axcess1/axcess1/Request_Detail.aspx?ReqIdentity=23207">A-2018-0013</a><o:p></o:p></span></u></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>DG<o:p></o:p></span></p>
          </td>
          <td width=177 valign=top style='width:132.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>all correspondence<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Simone
          Sunshine<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Business<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Online Request<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=127 valign=top style='width:95.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2019-01-23<o:p></o:p></span></p>
          </td>
          <td width=85 valign=top style='width:63.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2019-02-22<o:p></o:p></span></p>
          </td>
          <td width=81 valign=top style='width:60.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=40 valign=top style='width:30.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>30<o:p></o:p></span></p>
          </td>
          <td width=43 valign=top style='width:32.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>660<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>4<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:16;height:15.1pt'>
          <td width=35 valign=top style='width:26.25pt;border:solid silver 1.0pt;
          border-top:none;mso-border-top-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>13<o:p></o:p></span></p>
          </td>
          <td width=86 valign=top style='width:64.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><u><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:blue'><a
          href="http://hpvs426/Axcess1/axcess1/Request_Detail.aspx?ReqIdentity=22209">A-2018-12</a><o:p></o:p></span></u></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=177 valign=top style='width:132.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Leah McDaniel<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Business<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=127 valign=top style='width:95.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=85 valign=top style='width:63.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=81 valign=top style='width:60.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=40 valign=top style='width:30.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>30<o:p></o:p></span></p>
          </td>
          <td width=43 valign=top style='width:32.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>265<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:17;height:.25in'>
          <td width=121 colspan=2 valign=top style='width:90.75pt;border:none;
          mso-border-top-alt:solid black 1.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Totals<o:p></o:p></span></i></b></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border:none;mso-border-top-alt:
          solid black 1.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=177 valign=top style='width:132.75pt;border:none;
          mso-border-top-alt:solid black 1.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border:none;mso-border-top-alt:
          solid black 1.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=395 colspan=4 valign=top style='width:296.3pt;border:none;
          mso-border-top-alt:solid black 1.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=85 valign=top style='width:63.75pt;border:none;mso-border-top-alt:
          solid black 1.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=81 valign=top style='width:60.75pt;border:none;mso-border-top-alt:
          solid black 1.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=136 colspan=3 valign=top style='width:102.0pt;border:none;
          mso-border-top-alt:solid black 1.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;border:none;mso-border-top-alt:
          solid black 1.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=43 valign=top style='width:32.25pt;border:none;mso-border-top-alt:
          solid black 1.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;border:none;mso-border-top-alt:
          solid black 1.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border:none;mso-border-top-alt:
          solid black 1.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:18;height:.25in'>
          <td width=121 colspan=2 valign=top style='width:90.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Requests<o:p></o:p></span></i></b></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=177 valign=top style='width:132.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>13<o:p></o:p></span></b></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=395 colspan=4 valign=top style='width:296.3pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=85 valign=top style='width:63.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=81 valign=top style='width:60.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=136 colspan=3 valign=top style='width:102.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=43 valign=top style='width:32.25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:19;height:.25in'>
          <td width=121 colspan=2 valign=top style='width:90.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Pages Reviewed<o:p></o:p></span></i></b></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=177 valign=top style='width:132.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2,226<o:p></o:p></span></b></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=127 valign=top style='width:95.25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=85 valign=top style='width:63.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=81 valign=top style='width:60.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=40 valign=top style='width:30.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=43 valign=top style='width:32.25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:20;mso-yfti-lastrow:yes;height:.25in'>
          <td width=121 colspan=2 valign=top style='width:90.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Pages Released<o:p></o:p></span></i></b></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=177 valign=top style='width:132.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>169<o:p></o:p></span></b></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=127 valign=top style='width:95.25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=85 valign=top style='width:63.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=81 valign=top style='width:60.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=40 valign=top style='width:30.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=43 valign=top style='width:32.25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
         </tr>
        </table>
        </td>
       </tr>
      </table>
      </td>
     </tr>
    </table>
    </td>
   </tr>
   <tr style='mso-yfti-irow:2;mso-yfti-lastrow:yes'>
    <td style='padding:0in 0in 0in 0in'>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
     width=1312 style='width:984.1pt;border-collapse:collapse;mso-yfti-tbllook:
     1184;mso-padding-alt:0in 0in 0in 0in' cols=5>
     <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;height:11.25pt'>
      <td width=0 style='width:.3pt;padding:0in 0in 0in 0in;height:11.25pt'></td>
      <td width=2 style='width:1.5pt;padding:0in 0in 0in 0in;height:11.25pt;
      min-width: 0.53mm'></td>
      <td width=192 style='width:2.0in;padding:0in 0in 0in 0in;height:11.25pt;
      min-width: 50.80mm'></td>
      <td width=830 style='width:622.55pt;padding:0in 0in 0in 0in;height:11.25pt;
      min-width: 219.60mm'></td>
      <td width=288 style='width:216.05pt;padding:0in 0in 0in 0in;height:11.25pt;
      min-width: 76.20mm'></td>
     </tr>
     <tr style='mso-yfti-irow:1;height:9.0pt'>
      <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in;
      height:9.0pt'></td>
      <td width=2 valign=top style='width:1.5pt;padding:0in 0in 0in 0in;
      height:9.0pt;min-width: 0.53mm'></td>
      <td width=192 valign=top style='width:2.0in;padding:0in 0in 0in 0in;
      height:9.0pt;min-width: 50.80mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:9.0pt'>
        <td width=192 valign=top style='width:2.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:9.0pt;min-width: 50.80mm'>
        <p class=MsoNormal><b><i><span style='font-size:8.0pt;mso-fareast-font-family:
        "Times New Roman";color:black'>2020-11-13 12:41:13 PM<o:p></o:p></span></i></b></p>
        </td>
       </tr>
      </table>
      </td>
      <td width=830 valign=top style='width:622.55pt;padding:0in 0in 0in 0in;
      height:9.0pt;min-width: 219.60mm'></td>
      <td width=288 valign=top style='width:216.05pt;padding:0in 0in 0in 0in;
      height:9.0pt;min-width: 76.20mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:9.0pt'>
        <td width=192 valign=top style='width:2.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:9.0pt;min-width: 50.80mm'>
        <p class=MsoNormal align=right style='text-align:right'><b><i><span
        style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
        color:black'>Page 1 / 1<o:p></o:p></span></i></b></p>
        </td>
       </tr>
      </table>
      </td>
     </tr>
     <tr style='mso-yfti-irow:2;mso-yfti-lastrow:yes;height:13.5pt'>
      <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in;
      height:13.5pt'></td>
      <td width=1024 colspan=3 valign=top style='width:768.1pt;padding:0in 0in 0in 0in;
      height:13.5pt;min-width: 270.93mm'></td>
      <td width=288 valign=top style='width:216.05pt;padding:0in 0in 0in 0in;
      height:13.5pt;min-width: 76.20mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:9.0pt'>
        <td width=192 valign=top style='width:2.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:9.0pt;min-width: 50.80mm'>
        <p class=MsoNormal align=right style='text-align:right'><b><i><span
        style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
        color:black'>Axcess-1<o:p></o:p></span></i></b></p>
        </td>
       </tr>
      </table>
      </td>
     </tr>
    </table>
    </td>
   </tr>
  </table>
  </td>
  <td width="100%" style='width:100.0%;padding:0in 0in 0in 0in'></td>
 </tr>
 <tr style='mso-yfti-irow:1;mso-yfti-lastrow:yes'>
  <td width=0 style='width:.3pt;padding:0in 0in 0in 0in'></td>
  <td style='padding:0in 0in 0in 0in'></td>
 </tr>
</table>


        </v-col>

      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>

//import { mapGetters, mapState} from "vuex";
import axcessApi from "@/plugins/axcessApi";
export default {
  props:['organisation','dateFrom','dateTo','reportId'],
  created(){
    this.generateReport();
  },
  methods: {
    generateReport() {
      let data = {
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        reportId: this.reportId

      }
      const response = axcessApi.postRequest("/report/tbs",data);
      response.then(output => {
        console.log(output);
      })
    },
    selected() {
    }
  }
};
</script>
<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col id="showall" cols="12" sm="12" md="12">

<table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
 style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
 <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
  <td style='padding:0in 0in 0in 0in'>
  <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
   style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
   <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
    <td style='padding:0in 0in 0in 0in'>
    <div>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
     width=1440 style='width:15.0in;border-collapse:collapse;mso-yfti-tbllook:
     1184;mso-padding-alt:0in 0in 0in 0in'>
     <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;height:.25in'>
      <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in;
      height:.25in'></td>
      <td width=23 valign=top style='width:17.25pt;padding:0in 0in 0in 0in;
      height:.25in;min-width: 6.09mm'></td>
      <td width=1440 valign=top style='width:15.0in;padding:0in 0in 0in 0in;
      height:.25in;min-width: 607.75mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:.25in'>
        <td width=1440 valign=top style='width:15.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in;min-width: 561.71mm'>
        <!--<p class=MsoNormal align=center style='text-align:center'><b><span
        style='font-size:14.0pt;mso-fareast-font-family:"Times New Roman";
        color:black'>Request Dashboard - Standard<o:p></o:p></span></b></p>-->
              <p class=MsoNormal align=center style='text-align:center'><b><span
        style='margin-left:-900px;font-size:14.0pt;mso-fareast-font-family:"Times New Roman";
        color:black'>Summary of Completed Access to Information Requests<o:p></o:p></span></b></p>
        </td>
       </tr>
      </table>
      </td>
     </tr>
     <tr style='mso-yfti-irow:1;mso-yfti-lastrow:yes;height:22.0pt'>
      <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in;
      height:22.0pt'></td>
      <td width=23 valign=top style='width:17.25pt;padding:0in 0in 0in 0in;
      height:22.0pt;min-width: 6.09mm'></td>
      <td width=1440 valign=top style='width:15.0in;padding:0in 0in 0in 0in;
      height:22.0pt;min-width: 607.75mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:.25in'>
        <td width=1440 valign=top style='width:15.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in;min-width: 561.71mm'>
        <p class=MsoNormal align=center style='text-align:center'><b><span
        style='margin-left:-900px;font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
        color:black'>Request Type: Axcess Requests, Request Status: Complete Requests, Report Type: Requests with Date Received from
        {{dateFrom}} to {{dateTo}}<o:p></o:p></span></b></p>
        </td>
       </tr>
      </table>
      </td>
     </tr>
    </table>
    </div>
    </td>
   </tr>
   <tr style='mso-yfti-irow:1'>
    <td style='padding:0in 0in 0in 0in'>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
     style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'
     cols=3>
     <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes'>
      <td valign=top style='padding:0in 0in 0in 0in'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       width=1440 style='width:15.0in;border-collapse:collapse;mso-yfti-tbllook:
       1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;height:2.5pt'>
        <td width=0 style='width:.3pt;padding:0in 0in 0in 0in;height:2.5pt'></td>
        <td width=3 style='width:2.25pt;padding:0in 0in 0in 0in;height:2.5pt;
        min-width: 0.79mm'></td>
        <td width=1440 style='width:15.0in;padding:0in 0in 0in 0in;height:2.5pt;
        min-width: 613.04mm'></td>
       </tr>
       <tr style='mso-yfti-irow:1;mso-yfti-lastrow:yes'>
        <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in'></td>
        <td width=3 valign=top style='width:2.25pt;padding:0in 0in 0in 0in;
        min-width: 0.79mm'></td>
        <td width=1440 valign=top style='width:15.0in;padding:0in 0in 0in 0in;
        min-width: 613.04mm'>
        <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
         width=1440 style='width:15.0in;border-collapse:collapse;mso-yfti-tbllook:
         1184;mso-padding-alt:0in 0in 0in 0in;min-width: 613.04mm' cols=31>
         <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
          <td></td>
          <td width=129 style='width:96.75pt;padding:0in 0in 0in 0in;
          min-width: 34.13mm'></td>
          <td width=54 style='width:40.5pt;padding:0in 0in 0in 0in;min-width: 14.29mm'></td>
          <td width=211 style='width:158.3pt;padding:0in 0in 0in 0in;
          min-width: 55.83mm'></td>
          <td width=96 style='width:1.0in;padding:0in 0in 0in 0in;min-width: 25.40mm'></td>
          <td width=96 style='width:1.0in;padding:0in 0in 0in 0in;min-width: 25.40mm'></td>
          <td width=96 style='width:1.0in;padding:0in 0in 0in 0in;min-width: 25.40mm'></td>
          <td width=76 style='width:57.0pt;padding:0in 0in 0in 0in;min-width: 20.11mm'></td>
          <td width=76 style='width:57.0pt;padding:0in 0in 0in 0in;min-width: 20.11mm'></td>
          <td width=76 style='width:57.0pt;padding:0in 0in 0in 0in;min-width: 20.11mm'></td>
          <td width=76 style='width:57.0pt;padding:0in 0in 0in 0in;min-width: 20.11mm'></td>
          <td width=76 style='width:57.0pt;padding:0in 0in 0in 0in;min-width: 20.11mm'></td>
          <td width=96 style='width:1.0in;padding:0in 0in 0in 0in;min-width: 25.40mm'></td>
          <td width=90 style='width:67.5pt;padding:0in 0in 0in 0in;min-width: 23.81mm'></td>


         </tr>
         <tr style='mso-yfti-irow:1;height:.25in'>
          <td width=1440 colspan=12 valign=top style='width:15.0in;border:solid darkgray 1.0pt;
          border-bottom:none;mso-border-top-alt:solid darkgray .25pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-right-alt:solid darkgray .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='mso-fareast-font-family:"Times New Roman";color:black'>Request
          Details<o:p></o:p></span></i></b></p>
          </td>
  
         </tr>
       
         <tr style='mso-yfti-irow:3;height:27.15pt'>
          <td width=35 valign=top style='width:26.25pt;border-top:none;
          border-left:solid darkgray 1.0pt;border-bottom:solid silver 1.0pt;
          border-right:solid darkgray 1.0pt;mso-border-left-alt:solid darkgray .25pt;
          mso-border-bottom-alt:solid silver .25pt;mso-border-right-alt:solid darkgray .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:27.15pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Id<o:p></o:p></span></i></p>
          </td>
          <td width=129 valign=top style='width:96.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid darkgray 1.0pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-left-alt:solid darkgray .25pt;
          mso-border-bottom-alt:solid silver .25pt;mso-border-right-alt:solid darkgray .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:27.15pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Request<o:p></o:p></span></i></p>
          </td>
          <td width=54 valign=top style='width:40.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid darkgray 1.0pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-left-alt:solid darkgray .25pt;
          mso-border-bottom-alt:solid silver .25pt;mso-border-right-alt:solid darkgray .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:27.15pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Officer<o:p></o:p></span></i></p>
          </td>
          <td width=211 valign=top style='width:158.3pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid darkgray 1.0pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-left-alt:solid darkgray .25pt;
          mso-border-bottom-alt:solid silver .25pt;mso-border-right-alt:solid darkgray .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:27.15pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Summary Text<o:p></o:p></span></i></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid darkgray 1.0pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-left-alt:solid darkgray .25pt;
          mso-border-bottom-alt:solid silver .25pt;mso-border-right-alt:solid darkgray .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:27.15pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Request status<o:p></o:p></span></i></p>
          </td>
          
          
         
       
         
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid darkgray 1.0pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-left-alt:solid darkgray .25pt;
          mso-border-bottom-alt:solid silver .25pt;mso-border-right-alt:solid darkgray .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:27.15pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Applicant<o:p></o:p></span></i></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid darkgray 1.0pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-left-alt:solid darkgray .25pt;
          mso-border-bottom-alt:solid silver .25pt;mso-border-right-alt:solid darkgray .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:27.15pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Source<o:p></o:p></span></i></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid darkgray 1.0pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-left-alt:solid darkgray .25pt;
          mso-border-bottom-alt:solid silver .25pt;mso-border-right-alt:solid darkgray .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:27.15pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Organization<o:p></o:p></span></i></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid darkgray 1.0pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-left-alt:solid darkgray .25pt;
          mso-border-bottom-alt:solid silver .25pt;mso-border-right-alt:solid darkgray .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:27.15pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Date Complete Received<o:p></o:p></span></i></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid darkgray 1.0pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-left-alt:solid darkgray .25pt;
          mso-border-bottom-alt:solid silver .25pt;mso-border-right-alt:solid darkgray .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:27.15pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Disposition<o:p></o:p></span></i></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid darkgray 1.0pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-left-alt:solid darkgray .25pt;
          mso-border-bottom-alt:solid silver .25pt;mso-border-right-alt:solid darkgray .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:27.15pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Date Closed<o:p></o:p></span></i></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid darkgray 1.0pt;
          mso-border-top-alt:solid darkgray .25pt;mso-border-left-alt:solid darkgray .25pt;
          mso-border-alt:solid darkgray .25pt;mso-border-bottom-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:27.15pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Pages<o:p></o:p></span></i></p>
          </td>
       
      
        
         </tr>
         <tr style='mso-yfti-irow:4;height:15.1pt'>
          <td width=35 valign=top style='width:26.25pt;border:solid silver 1.0pt;
          border-top:none;mso-border-top-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>1<o:p></o:p></span></p>
          </td>
          <td width=129 valign=top style='width:96.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><u><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:blue'><a
          href="http://hpvs426/Axcess1/axcess1/Request_Detail.aspx?ReqIdentity=20188">A-2017-00002</a><o:p></o:p></span></u></p>
          </td>
          <td width=54 valign=top style='width:40.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>ML<o:p></o:p></span></p>
          </td>
          <td width=211 valign=top style='width:158.3pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>All and any information on the monies spent on
          Autonomous Community Solutions from January 2011 to present day.<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Complete<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
      
    
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Kelly
          salamander<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Public<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>Treasury Board of Canada Secretariat</span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2017-04-07<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>All Disclosed<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2017-04-27<o:p></o:p></span></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>345<o:p></o:p></span></p>
          </td>
          
         
         
         
        
         </tr>
         <tr style='mso-yfti-irow:5;height:15.1pt'>
          <td width=35 valign=top style='width:26.25pt;border:solid silver 1.0pt;
          border-top:none;mso-border-top-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2<o:p></o:p></span></p>
          </td>
          <td width=129 valign=top style='width:96.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><u><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:blue'><a
          href="http://hpvs426/Axcess1/axcess1/Request_Detail.aspx?ReqIdentity=20194">A-2017-00003</a><o:p></o:p></span></u></p>
          </td>
          <td width=54 valign=top style='width:40.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>ML<o:p></o:p></span></p>
          </td>
          <td width=211 valign=top style='width:158.3pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>All records related to Eco Oils Solutions Corporation
          between October 1, 2016 and September 7, 2017.<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Complete<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Raymond
          McDonald<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Business<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>Treasury Board of Canada Secretariat<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2017-09-08<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>All Disclosed<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2017-10-04<o:p></o:p></span></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>400<o:p></o:p></span></p>
          </td>
     

         </tr>
         <tr style='mso-yfti-irow:6;height:15.1pt'>
          <td width=35 valign=top style='width:26.25pt;border:solid silver 1.0pt;
          border-top:none;mso-border-top-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>3<o:p></o:p></span></p>
          </td>
          <td width=129 valign=top style='width:96.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><u><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:blue'><a
          href="http://hpvs426/Axcess1/axcess1/Request_Detail.aspx?ReqIdentity=20195">A-2017-00004</a><o:p></o:p></span></u></p>
          </td>
          <td width=54 valign=top style='width:40.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>ML<o:p></o:p></span></p>
          </td>
          <td width=211 valign=top style='width:158.3pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>All info on Saltworks Technologies, Axine and Forward
          Water projects<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Complete<o:p></o:p></span></p>
          </td>
       
   
        
     
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Randall
          O'Callaghan<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Business<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>Treasury Board of Canada Secretariat<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2017-11-23<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>All Disclosed<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-01-31<o:p></o:p></span></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>85<o:p></o:p></span></p>
          </td>
         
         
         
         </tr>
         <tr style='mso-yfti-irow:7;height:15.1pt'>
          <td width=35 valign=top style='width:26.25pt;border:solid silver 1.0pt;
          border-top:none;mso-border-top-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>4<o:p></o:p></span></p>
          </td>
          <td width=129 valign=top style='width:96.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><u><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:blue'><a
          href="http://hpvs426/Axcess1/axcess1/Request_Detail.aspx?ReqIdentity=20196">A-2017-00005</a><o:p></o:p></span></u></p>
          </td>
          <td width=54 valign=top style='width:40.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>ML<o:p></o:p></span></p>
          </td>
          <td width=211 valign=top style='width:158.3pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>Nous requérons copie de tout document relatif à toute
          demande de subvention faite par la Ville de Montréal ou toute autre
          personne en vue d’obtenir une aide financière pour l’organisation du
          championnat de Formule E.<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Complete<o:p></o:p></span></p>
          </td>
        
         
    
       
          
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Angela
          Pigllywiggly<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Business<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>Treasury Board of Canada Secretariat<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-02-01<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>All Disclosed<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-02-20<o:p></o:p></span></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>97<o:p></o:p></span></p>
          </td>
         
         </tr>
         <tr style='mso-yfti-irow:8;height:15.1pt'>
          <td width=35 valign=top style='width:26.25pt;border:solid silver 1.0pt;
          border-top:none;mso-border-top-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>5<o:p></o:p></span></p>
          </td>
          <td width=129 valign=top style='width:96.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><u><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:blue'><a
          href="http://hpvs426/Axcess1/axcess1/Request_Detail.aspx?ReqIdentity=20198">A-2017-00006</a><o:p></o:p></span></u></p>
          </td>
          <td width=54 valign=top style='width:40.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>ML<o:p></o:p></span></p>
          </td>
          <td width=211 valign=top style='width:158.3pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>All briefing materials and memos prepared for the CEO of
          Sustainable Development Technology Canada in February 2018.<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Complete<o:p></o:p></span></p>
          </td>
         
     
        
       
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Alia Rotherman<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Media<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-04-13<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-06-12<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>30<o:p></o:p></span></p>
          </td>
     
         </tr>
         <tr style='mso-yfti-irow:9;height:15.1pt'>
          <td width=35 valign=top style='width:26.25pt;border:solid silver 1.0pt;
          border-top:none;mso-border-top-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>6<o:p></o:p></span></p>
          </td>
          <td width=129 valign=top style='width:96.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><u><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:blue'><a
          href="http://hpvs426/Axcess1/axcess1/Request_Detail.aspx?ReqIdentity=20199">A-2018-0001</a><o:p></o:p></span></u></p>
          </td>
          <td width=54 valign=top style='width:40.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>ML<o:p></o:p></span></p>
          </td>
          <td width=211 valign=top style='width:158.3pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>All agreements, applications, contractual documents, or
          records relating to any agreement, application or contract between
          SDTC and Enerkem.<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Complete<o:p></o:p></span></p>
          </td>
      
     
     
      
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Leah McDaniel<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Business<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-04-10<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-07-09<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:10;height:15.1pt'>
          <td width=35 valign=top style='width:26.25pt;border:solid silver 1.0pt;
          border-top:none;mso-border-top-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>7<o:p></o:p></span></p>
          </td>
          <td width=129 valign=top style='width:96.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><u><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:blue'><a
          href="http://hpvs426/Axcess1/axcess1/Request_Detail.aspx?ReqIdentity=20200">A-2018-0002</a><o:p></o:p></span></u></p>
          </td>
          <td width=54 valign=top style='width:40.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>ML<o:p></o:p></span></p>
          </td>
          <td width=211 valign=top style='width:158.3pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>All briefing materials and memos prepared for the CEO of
          Sustainable Development Technology Canada in March 2018.<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Complete<o:p></o:p></span></p>
          </td>

  
    
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Zebeda
          Sallinger<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Media<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-04-27<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-05-28<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-05-17<o:p></o:p></span></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:11;height:15.1pt'>
          <td width=35 valign=top style='width:26.25pt;border:solid silver 1.0pt;
          border-top:none;mso-border-top-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>8<o:p></o:p></span></p>
          </td>
          <td width=129 valign=top style='width:96.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><u><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:blue'><a
          href="http://hpvs426/Axcess1/axcess1/Request_Detail.aspx?ReqIdentity=20202">A-2018-0003</a><o:p></o:p></span></u></p>
          </td>
          <td width=54 valign=top style='width:40.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>ML<o:p></o:p></span></p>
          </td>
          <td width=211 valign=top style='width:158.3pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>All briefing materials and memos prepared for the
          President &amp; CEO of SDTC for the month of March 2018<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Complete<o:p></o:p></span></p>
          </td>
        
  
      
        
       
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Zebeda
          Sallinger<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Media<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:12;height:15.1pt'>
          <td width=35 valign=top style='width:26.25pt;border:solid silver 1.0pt;
          border-top:none;mso-border-top-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>9<o:p></o:p></span></p>
          </td>
          <td width=129 valign=top style='width:96.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><u><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:blue'><a
          href="http://hpvs426/Axcess1/axcess1/Request_Detail.aspx?ReqIdentity=20203">A-2018-0004</a><o:p></o:p></span></u></p>
          </td>
          <td width=54 valign=top style='width:40.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>ML<o:p></o:p></span></p>
          </td>
          <td width=211 valign=top style='width:158.3pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>All briefing materials and memos prepared for the
          President &amp; CEO of SDTC for the month of April 2018<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Complete<o:p></o:p></span></p>
          </td>
    
     
        
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Zebeda
          Sallinger<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Media<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
         
         </tr>
         <tr style='mso-yfti-irow:13;height:15.1pt'>
          <td width=35 valign=top style='width:26.25pt;border:solid silver 1.0pt;
          border-top:none;mso-border-top-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>10<o:p></o:p></span></p>
          </td>
          <td width=129 valign=top style='width:96.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><u><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:blue'><a
          href="http://hpvs426/Axcess1/axcess1/Request_Detail.aspx?ReqIdentity=20204">A-2018-0005</a><o:p></o:p></span></u></p>
          </td>
          <td width=54 valign=top style='width:40.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>ML<o:p></o:p></span></p>
          </td>
          <td width=211 valign=top style='width:158.3pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Complete<o:p></o:p></span></p>
          </td>
 
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Alia Rotherman<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Media<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-05-26<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-06-25<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
        
         </tr>
         <tr style='mso-yfti-irow:14;height:15.1pt'>
          <td width=35 valign=top style='width:26.25pt;border:solid silver 1.0pt;
          border-top:none;mso-border-top-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>11<o:p></o:p></span></p>
          </td>
          <td width=129 valign=top style='width:96.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><u><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:blue'><a
          href="http://hpvs426/Axcess1/axcess1/Request_Detail.aspx?ReqIdentity=20205">A-2018-0006</a><o:p></o:p></span></u></p>
          </td>
          <td width=54 valign=top style='width:40.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>ML<o:p></o:p></span></p>
          </td>
          <td width=211 valign=top style='width:158.3pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Complete<o:p></o:p></span></p>
          </td>
         
    
       
      
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Kelly
          salamander<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Public<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-05-26<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-06-25<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
         
         </tr>
         <tr style='mso-yfti-irow:15;height:15.1pt'>
          <td width=35 valign=top style='width:26.25pt;border:solid silver 1.0pt;
          border-top:none;mso-border-top-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>12<o:p></o:p></span></p>
          </td>
          <td width=129 valign=top style='width:96.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><u><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:blue'><a
          href="http://hpvs426/Axcess1/axcess1/Request_Detail.aspx?ReqIdentity=20206">A-2018-0007</a><o:p></o:p></span></u></p>
          </td>
          <td width=54 valign=top style='width:40.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>ML<o:p></o:p></span></p>
          </td>
          <td width=211 valign=top style='width:158.3pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Complete<o:p></o:p></span></p>
          </td>
       
    
   
       
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Kelly
          salamander<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Public<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-05-28<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-06-27<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
         
         </tr>
         <tr style='mso-yfti-irow:16;height:15.1pt'>
          <td width=35 valign=top style='width:26.25pt;border:solid silver 1.0pt;
          border-top:none;mso-border-top-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>13<o:p></o:p></span></p>
          </td>
          <td width=129 valign=top style='width:96.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><u><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:blue'><a
          href="http://hpvs426/Axcess1/axcess1/Request_Detail.aspx?ReqIdentity=21206">A-2018-0008</a><o:p></o:p></span></u></p>
          </td>
          <td width=54 valign=top style='width:40.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>ML<o:p></o:p></span></p>
          </td>
          <td width=211 valign=top style='width:158.3pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Complete<o:p></o:p></span></p>
          </td>
          
       
      
      
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Zebeda
          Sallinger<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Media<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-06-04<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-07-04<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=40 valign=top style='width:30.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>30<o:p></o:p></span></p>
          </td>
          <td width=43 valign=top style='width:32.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>893<o:p></o:p></span></p>
          </td>
          <td width=39 valign=top style='width:29.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>No<o:p></o:p></span></p>
          </td>
          <td width=37 valign=top style='width:27.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>No<o:p></o:p></span></p>
          </td>
          <td width=118 valign=top style='width:88.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Disclosed in
          Part<o:p></o:p></span></p>
          </td>
          <td width=129 valign=top style='width:96.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>CD ROM<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>7<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=44 valign=top style='width:33.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=53 valign=top style='width:39.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:17;height:15.1pt'>
          <td width=35 valign=top style='width:26.25pt;border:solid silver 1.0pt;
          border-top:none;mso-border-top-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>14<o:p></o:p></span></p>
          </td>
          <td width=129 valign=top style='width:96.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><u><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:blue'><a
          href="http://hpvs426/Axcess1/axcess1/Request_Detail.aspx?ReqIdentity=21207">A-2018-0009</a><o:p></o:p></span></u></p>
          </td>
          <td width=54 valign=top style='width:40.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>DG<o:p></o:p></span></p>
          </td>
          <td width=211 valign=top style='width:158.3pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>This is summary text<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Complete<o:p></o:p></span></p>
          </td>
       
       
 
        
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Alia Rotherman<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Media<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-08-09<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-11-22<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>45<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=40 valign=top style='width:30.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>30<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>105<o:p></o:p></span></p>
          </td>
          <td width=43 valign=top style='width:32.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>827<o:p></o:p></span></p>
          </td>
          <td width=39 valign=top style='width:29.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>No<o:p></o:p></span></p>
          </td>
          <td width=37 valign=top style='width:27.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>No<o:p></o:p></span></p>
          </td>
          <td width=118 valign=top style='width:88.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Disclosed in
          Part<o:p></o:p></span></p>
          </td>
          <td width=129 valign=top style='width:96.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>CD ROM<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>3<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=44 valign=top style='width:33.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=53 valign=top style='width:39.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:18;height:15.1pt'>
          <td width=35 valign=top style='width:26.25pt;border:solid silver 1.0pt;
          border-top:none;mso-border-top-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>15<o:p></o:p></span></p>
          </td>
          <td width=129 valign=top style='width:96.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><u><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:blue'><a
          href="http://hpvs426/Axcess1/axcess1/Request_Detail.aspx?ReqIdentity=22207">A-2018-0010</a><o:p></o:p></span></u></p>
          </td>
          <td width=54 valign=top style='width:40.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>DG<o:p></o:p></span></p>
          </td>
          <td width=211 valign=top style='width:158.3pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Complete<o:p></o:p></span></p>
          </td>
       
      
        
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Angela
          Pigllywiggly<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Business<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=40 valign=top style='width:30.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>30<o:p></o:p></span></p>
          </td>
          <td width=43 valign=top style='width:32.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=39 valign=top style='width:29.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>No<o:p></o:p></span></p>
          </td>
          <td width=37 valign=top style='width:27.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>No<o:p></o:p></span></p>
          </td>
          <td width=118 valign=top style='width:88.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Disclosed in
          Part<o:p></o:p></span></p>
          </td>
          <td width=129 valign=top style='width:96.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>CD ROM<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>38<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=44 valign=top style='width:33.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=53 valign=top style='width:39.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:19;height:15.1pt'>
          <td width=35 valign=top style='width:26.25pt;border:solid silver 1.0pt;
          border-top:none;mso-border-top-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>16<o:p></o:p></span></p>
          </td>
          <td width=129 valign=top style='width:96.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><u><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:blue'><a
          href="http://hpvs426/Axcess1/axcess1/Request_Detail.aspx?ReqIdentity=22208">A-2018-0011</a><o:p></o:p></span></u></p>
          </td>
          <td width=54 valign=top style='width:40.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=211 valign=top style='width:158.3pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Complete<o:p></o:p></span></p>
          </td>
         
       
      
       
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Randall
          O'Callaghan<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Business<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=40 valign=top style='width:30.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>30<o:p></o:p></span></p>
          </td>
          <td width=43 valign=top style='width:32.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=39 valign=top style='width:29.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>No<o:p></o:p></span></p>
          </td>
          <td width=37 valign=top style='width:27.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>No<o:p></o:p></span></p>
          </td>
          <td width=118 valign=top style='width:88.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Disclosed in
          Part<o:p></o:p></span></p>
          </td>
          <td width=129 valign=top style='width:96.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>CD ROM<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>4<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=44 valign=top style='width:33.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=53 valign=top style='width:39.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:20;height:15.1pt'>
          <td width=35 valign=top style='width:26.25pt;border:solid silver 1.0pt;
          border-top:none;mso-border-top-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>17<o:p></o:p></span></p>
          </td>
          <td width=129 valign=top style='width:96.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><u><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:blue'><a
          href="http://hpvs426/Axcess1/axcess1/Request_Detail.aspx?ReqIdentity=23207">A-2018-0013</a><o:p></o:p></span></u></p>
          </td>
          <td width=54 valign=top style='width:40.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>DG<o:p></o:p></span></p>
          </td>
          <td width=211 valign=top style='width:158.3pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>all correspondence<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Complete<o:p></o:p></span></p>
          </td>
      
    
     
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Simone
          Sunshine<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Business<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2019-01-23<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2019-02-22<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=40 valign=top style='width:30.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>30<o:p></o:p></span></p>
          </td>
          <td width=43 valign=top style='width:32.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>660<o:p></o:p></span></p>
          </td>
          <td width=39 valign=top style='width:29.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>No<o:p></o:p></span></p>
          </td>
          <td width=37 valign=top style='width:27.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>No<o:p></o:p></span></p>
          </td>
          <td width=118 valign=top style='width:88.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Disclosed in
          Part<o:p></o:p></span></p>
          </td>
          <td width=129 valign=top style='width:96.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>CD ROM<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>4<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=44 valign=top style='width:33.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=53 valign=top style='width:39.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:21;height:15.1pt'>
          <td width=35 valign=top style='width:26.25pt;border:solid silver 1.0pt;
          border-top:none;mso-border-top-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>18<o:p></o:p></span></p>
          </td>
          <td width=129 valign=top style='width:96.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><u><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:blue'><a
          href="http://hpvs426/Axcess1/axcess1/Request_Detail.aspx?ReqIdentity=22209">A-2018-12</a><o:p></o:p></span></u></p>
          </td>
          <td width=54 valign=top style='width:40.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=211 valign=top style='width:158.3pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Complete<o:p></o:p></span></p>
          </td>
     
      
        
       
        
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Leah McDaniel<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Business<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=40 valign=top style='width:30.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>30<o:p></o:p></span></p>
          </td>
          <td width=43 valign=top style='width:32.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=39 valign=top style='width:29.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>No<o:p></o:p></span></p>
          </td>
          <td width=37 valign=top style='width:27.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>No<o:p></o:p></span></p>
          </td>
          <td width=118 valign=top style='width:88.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Disclosed in
          Part<o:p></o:p></span></p>
          </td>
          <td width=129 valign=top style='width:96.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>CD ROM<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>265<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=44 valign=top style='width:33.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=53 valign=top style='width:39.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:22;height:15.1pt'>
          <td width=35 valign=top style='width:26.25pt;border:solid silver 1.0pt;
          border-top:none;mso-border-top-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>19<o:p></o:p></span></p>
          </td>
          <td width=129 valign=top style='width:96.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><u><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:blue'><a
          href="http://hpvs426/Axcess1/axcess1/Request_Detail.aspx?ReqIdentity=20189">AC-2017-00001</a><o:p></o:p></span></u></p>
          </td>
          <td width=54 valign=top style='width:40.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>ML<o:p></o:p></span></p>
          </td>
          <td width=211 valign=top style='width:158.3pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>All communications between SDTC and NRCan on ACS between
          October 1, 2014 and November 3, 2015.<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Complete<o:p></o:p></span></p>
          </td>
      
    
     
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Ali Norman<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>External Govt
          Dept<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2017-04-18<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2017-04-27<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2017-04-21<o:p></o:p></span></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=40 valign=top style='width:30.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>9<o:p></o:p></span></p>
          </td>
          <td width=43 valign=top style='width:32.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>3<o:p></o:p></span></p>
          </td>
          <td width=39 valign=top style='width:29.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Yes<o:p></o:p></span></p>
          </td>
          <td width=37 valign=top style='width:27.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>No<o:p></o:p></span></p>
          </td>
          <td width=118 valign=top style='width:88.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Disclosed in
          Part<o:p></o:p></span></p>
          </td>
          <td width=129 valign=top style='width:96.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Copies given<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>4<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>4<o:p></o:p></span></p>
          </td>
          <td width=44 valign=top style='width:33.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=53 valign=top style='width:39.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:23;height:15.1pt'>
          <td width=35 valign=top style='width:26.25pt;border:solid silver 1.0pt;
          border-top:none;mso-border-top-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>20<o:p></o:p></span></p>
          </td>
          <td width=129 valign=top style='width:96.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><u><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:blue'><a
          href="http://hpvs426/Axcess1/axcess1/Request_Detail.aspx?ReqIdentity=20191">AC-2017-00002</a><o:p></o:p></span></u></p>
          </td>
          <td width=54 valign=top style='width:40.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>ML<o:p></o:p></span></p>
          </td>
          <td width=211 valign=top style='width:158.3pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>Comms: House Cards prepared for the Minister of ISED for
          the month of January 2017<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Complete<o:p></o:p></span></p>
          </td>
 
    
       
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Tania
          Vascluari<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>External Govt
          Dept<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2017-06-19<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2017-06-26<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2017-06-19<o:p></o:p></span></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=40 valign=top style='width:30.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>7<o:p></o:p></span></p>
          </td>
          <td width=43 valign=top style='width:32.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=39 valign=top style='width:29.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>No<o:p></o:p></span></p>
          </td>
          <td width=37 valign=top style='width:27.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>No<o:p></o:p></span></p>
          </td>
          <td width=118 valign=top style='width:88.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>All Disclosed<o:p></o:p></span></p>
          </td>
          <td width=129 valign=top style='width:96.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Not Applicable<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>4<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>4<o:p></o:p></span></p>
          </td>
          <td width=44 valign=top style='width:33.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=53 valign=top style='width:39.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:24;height:15.1pt'>
          <td width=35 valign=top style='width:26.25pt;border:solid silver 1.0pt;
          border-top:none;mso-border-top-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>21<o:p></o:p></span></p>
          </td>
          <td width=129 valign=top style='width:96.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><u><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:blue'><a
          href="http://hpvs426/Axcess1/axcess1/Request_Detail.aspx?ReqIdentity=20192">AC-2017-00003</a><o:p></o:p></span></u></p>
          </td>
          <td width=54 valign=top style='width:40.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>ML<o:p></o:p></span></p>
          </td>
          <td width=211 valign=top style='width:158.3pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>Obtain complete copies of all correspondence / letters
          and e-mails sent and received by the Privacy Commissioner for the
          period from April 1, 2016 to August 24, 2016. Include correspondence
          from the Commissioner's investigations / verifications / reviews
          (closed). Include also correspondence addressed to the Commissioner
          and transmitted by him in connection with privacy breaches under the
          PA and PIPEDA as well as the notices transmitted under section 8 (2)
          (m) and PIPEDA and pursuant to section 9 (5) of PIPEDA.<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Complete<o:p></o:p></span></p>
          </td>
  
      
     
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Annabelle
          Lessismore<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>External Govt
          Dept<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2017-07-19<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2017-08-02<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2017-07-20<o:p></o:p></span></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=40 valign=top style='width:30.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>14<o:p></o:p></span></p>
          </td>
          <td width=43 valign=top style='width:32.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>1<o:p></o:p></span></p>
          </td>
          <td width=39 valign=top style='width:29.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>No<o:p></o:p></span></p>
          </td>
          <td width=37 valign=top style='width:27.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>No<o:p></o:p></span></p>
          </td>
          <td width=118 valign=top style='width:88.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>All Disclosed<o:p></o:p></span></p>
          </td>
          <td width=129 valign=top style='width:96.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Copies given<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>1<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>1<o:p></o:p></span></p>
          </td>
          <td width=44 valign=top style='width:33.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=53 valign=top style='width:39.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:25;height:15.1pt'>
          <td width=35 valign=top style='width:26.25pt;border:solid silver 1.0pt;
          border-top:none;mso-border-top-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>22<o:p></o:p></span></p>
          </td>
          <td width=129 valign=top style='width:96.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><u><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:blue'><a
          href="http://hpvs426/Axcess1/axcess1/Request_Detail.aspx?ReqIdentity=20193">AC-2017-00004</a><o:p></o:p></span></u></p>
          </td>
          <td width=54 valign=top style='width:40.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>ML<o:p></o:p></span></p>
          </td>
          <td width=211 valign=top style='width:158.3pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>All records relating to decision 11-03-PB-157 pertinent
          to the candidacy of { } from MAR 1, 2011 to AUG 31, 2012.<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Complete<o:p></o:p></span></p>
          </td>
       
   
      
       
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Maria
          Turnberry<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>External Govt
          Dept<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2017-08-04<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2017-08-21<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2017-08-09<o:p></o:p></span></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=40 valign=top style='width:30.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>16<o:p></o:p></span></p>
          </td>
          <td width=43 valign=top style='width:32.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>5<o:p></o:p></span></p>
          </td>
          <td width=39 valign=top style='width:29.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>No<o:p></o:p></span></p>
          </td>
          <td width=37 valign=top style='width:27.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>No<o:p></o:p></span></p>
          </td>
          <td width=118 valign=top style='width:88.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Disclosed in
          Part<o:p></o:p></span></p>
          </td>
          <td width=129 valign=top style='width:96.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Copies given<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>10<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=44 valign=top style='width:33.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=53 valign=top style='width:39.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:26;height:15.1pt'>
          <td width=35 valign=top style='width:26.25pt;border:solid silver 1.0pt;
          border-top:none;mso-border-top-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>23<o:p></o:p></span></p>
          </td>
          <td width=129 valign=top style='width:96.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><u><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:blue'><a
          href="http://hpvs426/Axcess1/axcess1/Request_Detail.aspx?ReqIdentity=20201">AC-2018-0001</a><o:p></o:p></span></u></p>
          </td>
          <td width=54 valign=top style='width:40.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>ML<o:p></o:p></span></p>
          </td>
          <td width=211 valign=top style='width:158.3pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>Clean Technology Economic Strategy Table Meeting Jan 25<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Complete<o:p></o:p></span></p>
          </td>
         
       
 
     
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Kimberly Eadie<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>External Govt
          Dept<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-05-10<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-05-24<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2018-05-17<o:p></o:p></span></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=40 valign=top style='width:30.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>14<o:p></o:p></span></p>
          </td>
          <td width=43 valign=top style='width:32.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>7<o:p></o:p></span></p>
          </td>
          <td width=39 valign=top style='width:29.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>No<o:p></o:p></span></p>
          </td>
          <td width=37 valign=top style='width:27.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>No<o:p></o:p></span></p>
          </td>
          <td width=118 valign=top style='width:88.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Disclosed in
          Part<o:p></o:p></span></p>
          </td>
          <td width=129 valign=top style='width:96.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Copies given<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>8<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>8<o:p></o:p></span></p>
          </td>
          <td width=44 valign=top style='width:33.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=53 valign=top style='width:39.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:27;height:15.1pt'>
          <td width=35 valign=top style='width:26.25pt;border:solid silver 1.0pt;
          border-top:none;mso-border-top-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>24<o:p></o:p></span></p>
          </td>
          <td width=129 valign=top style='width:96.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><u><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:blue'><a
          href="http://hpvs426/Axcess1/axcess1/Request_Detail.aspx?ReqIdentity=20190">PD-2017-00001</a><o:p></o:p></span></u></p>
          </td>
          <td width=54 valign=top style='width:40.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>ML<o:p></o:p></span></p>
          </td>
          <td width=211 valign=top style='width:158.3pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>The HIP index and evaluation framework used by SDTC to
          evaluate potential applicants.<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Complete<o:p></o:p></span></p>
          </td>
       
    
      
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Leah Larock<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Internal<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2017-04-21<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2017-05-12<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>2017-05-05<o:p></o:p></span></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=40 valign=top style='width:30.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>21<o:p></o:p></span></p>
          </td>
          <td width=43 valign=top style='width:32.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>14<o:p></o:p></span></p>
          </td>
          <td width=39 valign=top style='width:29.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>No<o:p></o:p></span></p>
          </td>
          <td width=37 valign=top style='width:27.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>No<o:p></o:p></span></p>
          </td>
          <td width=118 valign=top style='width:88.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>All Disclosed<o:p></o:p></span></p>
          </td>
          <td width=129 valign=top style='width:96.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Not Applicable<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>18<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>18<o:p></o:p></span></p>
          </td>
          <td width=44 valign=top style='width:33.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=53 valign=top style='width:39.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:28;height:15.1pt'>
          <td width=35 valign=top style='width:26.25pt;border:solid silver 1.0pt;
          border-top:none;mso-border-top-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>25<o:p></o:p></span></p>
          </td>
          <td width=129 valign=top style='width:96.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><u><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:blue'><a
          href="http://hpvs426/Axcess1/axcess1/Request_Detail.aspx?ReqIdentity=22206">PR-2018-0001</a><o:p></o:p></span></u></p>
          </td>
          <td width=54 valign=top style='width:40.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=211 valign=top style='width:158.3pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Complete<o:p></o:p></span></p>
          </td>
      
       
       
       
        
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Alia Rotherman<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Media<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=40 valign=top style='width:30.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=43 valign=top style='width:32.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=39 valign=top style='width:29.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>No<o:p></o:p></span></p>
          </td>
          <td width=37 valign=top style='width:27.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>No<o:p></o:p></span></p>
          </td>
          <td width=118 valign=top style='width:88.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Disclosed in
          Part<o:p></o:p></span></p>
          </td>
          <td width=129 valign=top style='width:96.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>CD ROM<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>127<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=44 valign=top style='width:33.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=53 valign=top style='width:39.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:29;height:15.1pt'>
          <td width=35 valign=top style='width:26.25pt;border:solid silver 1.0pt;
          border-top:none;mso-border-top-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>26<o:p></o:p></span></p>
          </td>
          <td width=129 valign=top style='width:96.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><u><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:blue'><a
          href="http://hpvs426/Axcess1/axcess1/Request_Detail.aspx?ReqIdentity=23208">PR-2018-0002</a><o:p></o:p></span></u></p>
          </td>
          <td width=54 valign=top style='width:40.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=211 valign=top style='width:158.3pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Complete<o:p></o:p></span></p>
          </td>
   
        
       
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Simone
          Sunshine<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Business<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=40 valign=top style='width:30.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=43 valign=top style='width:32.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=39 valign=top style='width:29.25pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>No<o:p></o:p></span></p>
          </td>
          <td width=37 valign=top style='width:27.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>No<o:p></o:p></span></p>
          </td>
          <td width=118 valign=top style='width:88.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>Disclosed in
          Part<o:p></o:p></span></p>
          </td>
          <td width=129 valign=top style='width:96.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>CD ROM<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>885<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=44 valign=top style='width:33.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
          <td width=53 valign=top style='width:39.75pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>0<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:30;height:.25in'>
          <td width=164 colspan=2 valign=top style='width:123.0pt;border:none;
          mso-border-top-alt:solid black 1.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Totals<o:p></o:p></span></i></b></p>
          </td>
          <td width=54 valign=top style='width:40.5pt;border:none;mso-border-top-alt:
          solid black 1.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=211 valign=top style='width:158.3pt;border:none;mso-border-top-alt:
          solid black 1.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border:none;mso-border-top-alt:
          solid black 1.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border:none;mso-border-top-alt:
          solid black 1.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border:none;mso-border-top-alt:
          solid black 1.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border:none;mso-border-top-alt:
          solid black 1.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border:none;mso-border-top-alt:
          solid black 1.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=152 colspan=2 valign=top style='width:114.0pt;border:none;
          mso-border-top-alt:solid black 1.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=262 colspan=3 valign=top style='width:196.5pt;border:none;
          mso-border-top-alt:solid black 1.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border:none;mso-border-top-alt:
          solid black 1.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border:none;mso-border-top-alt:
          solid black 1.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=136 colspan=3 valign=top style='width:102.0pt;border:none;
          mso-border-top-alt:solid black 1.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;border:none;mso-border-top-alt:
          solid black 1.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=43 valign=top style='width:32.25pt;border:none;mso-border-top-alt:
          solid black 1.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=39 valign=top style='width:29.25pt;border:none;mso-border-top-alt:
          solid black 1.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=37 valign=top style='width:27.75pt;border:none;mso-border-top-alt:
          solid black 1.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=118 valign=top style='width:88.5pt;border:none;mso-border-top-alt:
          solid black 1.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=129 valign=top style='width:96.75pt;border:none;mso-border-top-alt:
          solid black 1.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;border:none;mso-border-top-alt:
          solid black 1.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;border:none;mso-border-top-alt:
          solid black 1.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=44 valign=top style='width:33.0pt;border:none;mso-border-top-alt:
          solid black 1.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;border:none;mso-border-top-alt:
          solid black 1.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;border:none;mso-border-top-alt:
          solid black 1.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=53 valign=top style='width:39.75pt;border:none;mso-border-top-alt:
          solid black 1.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:31;height:.25in'>
          <td width=35 valign=top style='width:26.25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=129 valign=top style='width:96.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Requests<o:p></o:p></span></i></b></p>
          </td>
          <td width=54 valign=top style='width:40.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=211 valign=top style='width:158.3pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>26<o:p></o:p></span></b></p>
          </td>
          <td width=96 valign=top style='width:1.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=152 colspan=2 valign=top style='width:114.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=262 colspan=3 valign=top style='width:196.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=136 colspan=3 valign=top style='width:102.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=43 valign=top style='width:32.25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=39 valign=top style='width:29.25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=37 valign=top style='width:27.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=118 valign=top style='width:88.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=129 valign=top style='width:96.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=44 valign=top style='width:33.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=53 valign=top style='width:39.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:32;height:.25in'>
          <td width=35 valign=top style='width:26.25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=129 valign=top style='width:96.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Pages Reviewed<o:p></o:p></span></i></b></p>
          </td>
          <td width=54 valign=top style='width:40.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=211 valign=top style='width:158.3pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>4,990<o:p></o:p></span></b></p>
          </td>
          <td width=96 valign=top style='width:1.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=40 valign=top style='width:30.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=43 valign=top style='width:32.25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=39 valign=top style='width:29.25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=37 valign=top style='width:27.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=118 valign=top style='width:88.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=129 valign=top style='width:96.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=44 valign=top style='width:33.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=53 valign=top style='width:39.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:33;mso-yfti-lastrow:yes;height:.25in'>
          <td width=35 valign=top style='width:26.25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=129 valign=top style='width:96.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Pages Released<o:p></o:p></span></i></b></p>
          </td>
          <td width=54 valign=top style='width:40.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=211 valign=top style='width:158.3pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>573<o:p></o:p></span></b></p>
          </td>
          <td width=96 valign=top style='width:1.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=51 valign=top style='width:38.25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=40 valign=top style='width:30.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=43 valign=top style='width:32.25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=39 valign=top style='width:29.25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=37 valign=top style='width:27.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=118 valign=top style='width:88.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=129 valign=top style='width:96.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=45 valign=top style='width:33.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=44 valign=top style='width:33.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=53 valign=top style='width:39.75pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
         </tr>
        </table>
        </td>
       </tr>
      </table>
      </td>
     </tr>
    </table>
    </td>
   </tr>
   <tr style='mso-yfti-irow:2;mso-yfti-lastrow:yes'>
    <td style='padding:0in 0in 0in 0in'>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
     width=1440 style='width:15.0in;border-collapse:collapse;mso-yfti-tbllook:
     1184;mso-padding-alt:0in 0in 0in 0in' cols=5>
     <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;height:11.25pt'>
      <td width=0 style='width:.3pt;padding:0in 0in 0in 0in;height:11.25pt'></td>
      <td width=2 style='width:1.5pt;padding:0in 0in 0in 0in;height:11.25pt;
      min-width: 0.53mm'></td>
      <td width=192 style='width:2.0in;padding:0in 0in 0in 0in;height:11.25pt;
      min-width: 50.80mm'></td>
      <td width=1440 style='width:15.0in;padding:0in 0in 0in 0in;height:11.25pt;
      min-width: 465.67mm'></td>
      <td width=366 style='width:274.55pt;padding:0in 0in 0in 0in;height:11.25pt;
      min-width: 96.84mm'></td>
     </tr>
     <tr style='mso-yfti-irow:1;height:9.0pt'>
      <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in;
      height:9.0pt'></td>
      <td width=2 valign=top style='width:1.5pt;padding:0in 0in 0in 0in;
      height:9.0pt;min-width: 0.53mm'></td>
      <td width=192 valign=top style='width:2.0in;padding:0in 0in 0in 0in;
      height:9.0pt;min-width: 50.80mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:9.0pt'>
        <td width=192 valign=top style='width:2.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:9.0pt;min-width: 50.80mm'>
        <p class=MsoNormal><b><i><span style='font-size:8.0pt;mso-fareast-font-family:
        "Times New Roman";color:black'>2020-11-13 12:45:58 PM<o:p></o:p></span></i></b></p>
        </td>
       </tr>
      </table>
      </td>
      <td width=1440 valign=top style='width:15.0in;padding:0in 0in 0in 0in;
      height:9.0pt;min-width: 465.67mm'></td>
      <td width=366 valign=top style='width:274.55pt;padding:0in 0in 0in 0in;
      height:9.0pt;min-width: 96.84mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:9.0pt'>
        <td width=192 valign=top style='width:2.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:9.0pt;min-width: 50.80mm'>
        <p class=MsoNormal align=right style='text-align:right'><b><i><span
        style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
        color:black'>Page 1 / 1<o:p></o:p></span></i></b></p>
        </td>
       </tr>
      </table>
      </td>
     </tr>
     <tr style='mso-yfti-irow:2;mso-yfti-lastrow:yes;height:13.5pt'>
      <td width=0 valign=top style='width:.3pt;padding:0in 0in 0in 0in;
      height:13.5pt'></td>
      <td width=1440 colspan=3 valign=top style='width:15.0in;padding:0in 0in 0in 0in;
      height:13.5pt;min-width: 517.00mm'></td>
      <td width=366 valign=top style='width:274.55pt;padding:0in 0in 0in 0in;
      height:13.5pt;min-width: 96.84mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:9.0pt'>
        <td width=192 valign=top style='width:2.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:9.0pt;min-width: 50.80mm'>
        <p class=MsoNormal align=right style='text-align:right'><b><i><span
        style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
        color:black'>Axcess-1<o:p></o:p></span></i></b></p>
        </td>
       </tr>
      </table>
      </td>
     </tr>
    </table>
    </td>
   </tr>
  </table>
  </td>
  <td width="100%" style='width:100.0%;padding:0in 0in 0in 0in'></td>
 </tr>
 <tr style='mso-yfti-irow:1;mso-yfti-lastrow:yes'>
  <td width=0 style='width:.3pt;padding:0in 0in 0in 0in'></td>
  <td style='padding:0in 0in 0in 0in'></td>
 </tr>
</table>



        </v-col>

      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>

//import { mapGetters, mapState} from "vuex";
import axcessApi from "@/plugins/axcessApi";
export default {
  props:['requesttype','dateFrom','dateTo','reportId','requeststatus','reporttype'],
  created(){
    this.generateReport();
  },
  methods: {
    generateReport() {
      let data = {
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        reportId: this.reportId

      }
      const response = axcessApi.postRequest("/report/tbs",data);
      response.then(output => {
        console.log(output);
      })
    },
    selected() {
    }
  }
};
</script>

<style lang="scss" scoped>
#showall {
  overflow-x: auto;
  
}
</style>